import React, { useState } from "react"

function ContactForm(props) {
  const [contact, setContact] = useState({
    "your-name": "",
    "your-email": "",
    "your-subject": "",
    "your-message": "",
  })

  return (
    <form
      method="POST"
      action="https://mailthis.to/eli@adtack.com"
      className={`grid grid-cols-1 sm:grid-cols-2 gap-6 ${props.className}`}
    >
      <input
        style={{ display: "none" }}
        type="hidden"
        name="form-name"
        value="Contact"
      />
      <input
        name="Name"
        placeholder="YOUR NAME"
        className="py-2 px-4 border border-solid border-[#eeeeee] rounded"
        required
        type="text"
      />
      <input
        name="Email"
        placeholder="EMAIL"
        className="py-2 px-4 border border-solid border-[#eeeeee] rounded"
        required
        type="email"
      />
      <input
        name="Subject"
        placeholder="SUBJECT"
        className="sm:col-span-2 py-2 px-4 border border-solid border-[#eeeeee] rounded"
        type="text"
      />
      <textarea
        name="Message"
        placeholder="MESSAGE"
        className="sm:col-span-2 py-2 px-4 border border-solid border-[#eeeeee] rounded h-56"
      />
      <button
        type="submit"
        className="cta bg-red text-white uppercase font-bold"
      >
        Send Your Message
      </button>
    </form>
  )
}

export default ContactForm
