import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/ContactForm"
import Slider from "react-slick"
import sliderBg from "../images/slider-bg.jpg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const sliderSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <section className="relative py-16 px-5">
      <div className="absolute top-0 left-0 w-full h-full">
        <StaticImage src="../images/Bg.jpg" className="w-full h-full" />
      </div>
      <div className="relative text-white text-center">
        <StaticImage className="mb-16" src="../images/Retro Slogan.png" />
        <h1 className="sm:text-5xl text-2xl uppercase font-bold mb-8">
          Your MEDICAL Office Administration
          <br />
          Outsourced and Enhanced!
        </h1>
        <p className="font-lora max-w-2xl mx-auto text-white">
          <i>
            Providing professional medical office administration and life-cycle
            accounts receivable management resulting in a fast track to revenue
            realization.
          </i>
        </p>
        <ul className="font-lora text-left grid md:grid-cols-3 grid-cols-1 gap-4 max-w-6xl mx-auto mt-20 text-white list-none">
          <li className="flex space-x-6">
            <StaticImage src="../images/Group 1.jpg" quality={100} />
            <div className="flex flex-col justify-center">
              <p className="m-0 text-white">
                <i>Call Us Now</i>
              </p>
              <a className="text-xl text-white" href="tel: 8338560238">
                833-856-0238
              </a>
            </div>
          </li>
          <li className="flex space-x-6">
            <StaticImage src="../images/Group 2.jpg" quality={100} />
            <div className="flex flex-col justify-center">
              <p className="m-0 text-white">
                <i>Send Us Message</i>
              </p>
              <a className="text-xl text-white" href="mailto:info@marcmed.com">
                info@marcmed.com
              </a>
            </div>
          </li>
          <li className="flex space-x-6">
            <StaticImage src="../images/Group 3.jpg" quality={100} />
            <div className="flex flex-col justify-center">
              <p className="m-0 text-white">
                <i>Ask For</i>
              </p>
              <a className="text-xl text-white" href="mailto:info@marcmed.com">
                Free Consultation
              </a>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section
      id="about"
      className="flex container mx-auto md:space-x-16 my-16 px-5 md:flex-row flex-col"
    >
      <div>
        <h2 className="font-bold">THIS IS MARC</h2>
        <p className="text-red font-bold">
          MARC, we’re your Medical Accounts Receivable Consultants.
        </p>
        <p>
          We provide comprehensive medical office administration services and
          accounts receivable management for the medical practice community. Our
          approach is proven with two simple goals of (1) enhancing your patient
          experience and (2) maximizing and expediting your payment for
          services. We accomplish this through a best-in-class customer service
          and payment technology platform which is supported by seasoned staff
          with significant experience in medical office practice. We ensure
          quality service throughout the entire process to ensure the
          interactions your customers have with your brand are positive and
          productive.
        </p>
      </div>
      <StaticImage
        className="md:flex-shrink-0 md:w-auto w-full"
        src="../images/Image.jpg"
      />
    </section>
    <section className="flex mt-16 md:flex-row flex-col">
      <StaticImage className="md:w-[40%] w-full" src="../images/Image2.jpg" />
      <div
        id="services"
        className="md:w-[60%] w-full md:py-16 md:px-20 p-10 bg-light-grey"
      >
        <h2 className="font-bold">
          WE ARE HERE TO SERVE ALL YOUR MEDICAL OFFICE NEEDS
        </h2>
        <p>
          Our customer care platform provides for customer billing, answering
          customer billing inquiries, assistance in primary and secondary
          insurance claims, attorney lien oversight, private pay billing and
          follow up, detailed itemization, workmen’s compensation follow up and
          credit reporting with E-Oscar compliance. MARC designs a configurable
          workflow for each client we sign. Fully customizing the experience to
          cater to yours and your patient’s needs. We serve all types of medical
          companies.
        </p>

        <p className="m-0 underline font-bold">Additional services include:</p>
        <ul>
          <li>
            Assist in the processing of secondary insurance claims, workers
            compensation claims, obtaining patient documentation for insurance
            subrogation requirements
          </li>
          <li>
            Answer patient questions on dates of service, medical services
            rendered, insurance processing, insurance write offs, deductibles,
            co-pays and co-insurance
          </li>
          <li>
            Update patient insurance information and assist in timely claim
            filing
          </li>
          <li>Confirming Medicaid eligibility on delayed patient approval</li>
          <li>Bankruptcy claim filing</li>
          <li>Omnichannel Communication Approach</li>
          <li>Data Driven Attorney Referral Processes</li>
          <li>Data Driven Collection Agency Referral Processes</li>
        </ul>
        <h2 className="font-bold mt-8">Who We Serve</h2>
        <div className="grid grid-cols-1 xl:grid-cols-4 sm:grid-cols-2 mt-8">
          <div>
            <h3>Facilities</h3>
            <ul>
              <li>Hospital</li>
              <li>Labs</li>
              <li>Surgery Centers</li>
              <li>Clinics</li>
              <li>Behavior Health</li>
            </ul>
          </div>
          <div>
            <h3>Physicians</h3>
            <ul>
              <li>Doctors</li>
              <li>Dentist</li>
              <li>Physical Therapists</li>
              <li>Radiologists</li>
              <li>Optometrists</li>
              <li>Audiologists</li>
              <li>Chiropractic</li>
            </ul>
          </div>
          <div>
            <h3>Groups</h3>
            <ul>
              <li>Hospitalists</li>
              <li>Surgery</li>
              <li>Nursing</li>
              <li>Anesthesiologists</li>
            </ul>
          </div>
          <div>
            <h3>Emergency Services</h3>
            <ul>
              <li>Ambulance</li>
              <li>Fire and Rescue</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section>
      <Slider
        style={{ background: `url(${sliderBg}) no-repeat center / cover` }}
        className="text-center py-32 mb-16 px-5"
        {...sliderSettings}
      >
        <div className="text-2xl lg:text-4xl text-white font-bold">
          Patient care doesn’t end when the doctor walks out of the room
        </div>
        <div className="text-2xl lg:text-4xl text-white font-bold">
          MARC engages patients earlier, retains your customers longer, and
          reduces your servicing costs
        </div>
        <div className="text-2xl lg:text-4xl text-white font-bold">
          We ensure earned revenue is monetized in a friendly and fast way
        </div>
        <div className="text-2xl lg:text-4xl text-white font-bold">
          MARC is an extension of your current servicing processes without the
          headache of hiring
        </div>
      </Slider>
    </section>
    <section
      id="contact"
      className="container px-5 mx-auto grid md:grid-cols-3 grid-cols-1 md:gap-12 gap-6 mb-20"
    >
      <div className="md:col-span-2">
        <h2>GET IN TOUCH</h2>
        <p className="max-w-lg">
          Feel free to get in touch with any inquiries and one of our friendly
          members of staff will get back to you as soon as possible.
        </p>
        <ContactForm className="mt-10" />
      </div>
      <div>
        <ul className="list-none m-0 grid lg:grid-cols-2 grid-cols-1 font-open-sans">
          <li>
            <p>
              <span className="text-red">Phone:</span>
              <br />

              <a
                className="text-grey flex items-center space-x-3"
                href="tel:8338560238"
              >
                <StaticImage src="../images/icon-2.png" />
                <span>833-856-0238</span>
              </a>
            </p>
          </li>
          <li>
            <p>
              <span className="text-red">Email:</span>
              <br />

              <a
                className="text-grey flex items-center space-x-3"
                href="mailto:info@marcmed.com"
              >
                <StaticImage src="../images/icon-1.png" />
                <span>info@marcmed.com</span>
              </a>
            </p>
          </li>
          <li className="lg:col-span-2">
            <p>
              <span className="text-red">Address:</span>
              <br />

              <a
                className="text-grey flex items-center space-x-3"
                target="_blank"
                rel="noreferrer noopener"
              >
                <StaticImage src="../images/icon-3.png" />
                <span>
                  <span>
                    PO Box 401418 <br />
                    Las Vegas, NV 89140
                  </span>
                  <br />
                </span>
              </a>
            </p>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
)

export default IndexPage
